<template>
    <div>
        <div class="breadcrumb-wrap-style-2" data-bg-image="media/etablissement/etablissements.jpg">
            <div class="container">
                <div class="row">
                    <div class="inner-banner-title col-lg-10 col-xs-12">
                        <h1 class="title mb-3">Etablissements Scolaires et Universitaires</h1>
                        <p class="subtitle">Décrouvrez les établissements enregistés sur SCEM</p>
                    </div>
                </div>
            </div>
        </div>
        
        <!--=====================================-->
        <!--=     	 	 Blog Start     		=-->
        <!--=====================================-->
        <section class="blog-wrap-style-3">
            <div class="container">
                <!-- <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <input type="text" class="form-item custum-filter-input" placeholder="Rechercher un etablissement">
                        </div>
                    </div>
                </div> -->
                <div class="row masonry-items">
                    <div class="col-xl-4 col-md-6 col-12 masonry-item">
                        <div class="course-box style-2">
                            <div class="figure-box">
                                <img src="media/etablissement/Maternelle en classe 1.jpg" alt="Course" width="690" height="405" />
                            </div>
                            <div class="content-box">
                                <!-- <div class="admin-figure">
                                    <img src="media/responsable/divine.jpg" alt="Course" width="60" height="60" />
                                </div> -->
                                <div class="teacher-name">
                                    Ecole primaire
                                    <!-- <a href="" @click="$router.push({name: 'Gracedivine'})">Madame Gossé</a> -->
                                </div>
                                <!-- <div class="mb-1 d-flex justify-content-between align-items-center flex-wrap">
                                    <ul class="rating inline-list">
                                        <li><i class="fas fa-star"></i></li>
                                        <li>5.0</li>
                                    </ul>
                                </div> -->
                                <h3 class="cursor title">
                                    <a @click.prevent="reload()" @click="$router.push({name: 'Gracedivine'})">GROUPE SCOLAIRE GRACE DIVINE</a >
                                </h3>
                                <div class="cursor d-flex justify-content-between align-items-center flex-wrap">
                                    <a @click.prevent="reload()" @click="$router.push({name: 'Gracedivine'})" class="btn-savoir-plus">En savoir plus</a>
                                </div>
                            </div>
                        </div>
                    </div>
                  <!-- <div class="col-xl-4 col-md-6 col-12 masonry-item">
                    <div class="course-box style-2">
                        <div class="figure-box">
                            <img src="media/etablissement/Tourisme.jpg" alt="Course" width="690" height="405" />
                        </div>
                        <div class="content-box">
                            <div class="admin-figure">
                                <img src="media/etablissement/Tourisme.jpg" alt="Course" width="60" height="60" />
                            </div>
                            <div class="
                        mb-1
                        d-flex
                        justify-content-between
                        align-items-center
                        flex-wrap
                      ">

                            <ul class="rating inline-list">
                                <li><i class="fas fa-star"></i></li>
                                <li>5.0</li>
                            </ul>
                        </div>
                        <div class="teacher-name">
                            Formation - <a href="" @click="$router.push({name: 'Isthog'})">Professionnelle</a>
                        </div>
                        <h3 class="title">
                            <a href="" @click="$router.push({name: 'Isthog'})">ISTHOG-GUINEE (INSTITUT DE STRATEGIE, DU TOURISME, DE L'HOTELLERIE ET DE GESTION)...</a >
             
                        </h3>
                    <div
                      class="
                        d-flex
                        justify-content-between
                        align-items-center
                        flex-wrap
                      "
                    >
                   
                      <a href="" @click="$router.push({name: 'Isthog'})" class="item-btn btn-fill">Commencer votre inscription</a>
                        </div>
                    </div>
                </div>
            </div> -->
                <!-- <div class="col-xl-4 col-md-6 col-12 masonry-item">
                <div class="course-box style-2">
                    <div class="figure-box">
                        <img src="media/etablissement/grace_divine.png" alt="Course" width="690" height="405" />
                    </div>
                    <div class="content-box">
                        <div class="admin-figure">
                            <img src="media/responsable/divine.jpg" alt="Course" width="60" height="60" />
                        </div>
                        <div class="
                    mb-1
                    d-flex
                    justify-content-between
                    align-items-center
                    flex-wrap
                  ">

                            <ul class="rating inline-list">
                                <li><i class="fas fa-star"></i></li>
                                <li>5.0</li>
                            </ul>
                        </div>
                        <div class="teacher-name">
                            Directrice - <a href="profile.html">Madame Gossé</a>
                        </div>
                        <h3 class="title">
                            <a href="apropos.html">Le groupe scolaire GRACE DIVINE est un établissement d'excellence pour les études de vos enfants...</a >
         
                    </h3>
                <div
                  class="
                    d-flex
                    justify-content-between
                    align-items-center
                    flex-wrap
                  "
                >
               
                  <a href="contacts.html" class="item-btn btn-fill">Commencer votre inscription</a>
                    </div>
                </div>
            </div>
    </div>
    <div class="col-xl-4 col-md-6 col-12 masonry-item">
        <div class="course-box style-2">
            <div class="figure-box">
                <img src="media/etablissement/grace_divine.png" alt="Course" width="690" height="405" />
            </div>
            <div class="content-box">
                <div class="admin-figure">
                    <img src="media/responsable/divine.jpg" alt="Course" width="60" height="60" />
                </div>
                <div class="
                    mb-1
                    d-flex
                    justify-content-between
                    align-items-center
                    flex-wrap
                  ">

                    <ul class="rating inline-list">
                        <li><i class="fas fa-star"></i></li>
                        <li>5.0</li>
                    </ul>
                </div>
                <div class="teacher-name">
                    Directrice - <a href="profile.html">Madame Gossé</a>
                </div>
                <h3 class="title">
                    <a href="apropos.html">Le groupe scolaire GRACE DIVINE est un établissement d'excellence pour les études de vos enfants...</a >
         
                    </h3>
                <div
                  class="
                    d-flex
                    justify-content-between
                    align-items-center
                    flex-wrap
                  "
                >
               
                  <a href="contacts.html" class="item-btn btn-fill">Commencer votre inscription</a>
            </div>
        </div>
    </div>
    </div>

    <div class="col-xl-4 col-md-6 col-12 masonry-item">
        <div class="course-box style-2">
            <div class="figure-box">
                <img src="media/etablissement/grace_divine.png" alt="Course" width="690" height="405" />
            </div>
            <div class="content-box">
                <div class="admin-figure">
                    <img src="media/responsable/divine.jpg" alt="Course" width="60" height="60" />
                </div>
                <div class="
                    mb-1
                    d-flex
                    justify-content-between
                    align-items-center
                    flex-wrap
                  ">

                    <ul class="rating inline-list">
                        <li><i class="fas fa-star"></i></li>
                        <li>5.0</li>
                    </ul>
                </div>
                <div class="teacher-name">
                    Directrice - <a href="profile.html">Madame Gossé</a>
                </div>
                <h3 class="title">
                    <a href="apropos.html">Le groupe scolaire GRACE DIVINE est un établissement d'excellence pour les études de vos enfants...</a >
         
                    </h3>
                <div
                  class="
                    d-flex
                    justify-content-between
                    align-items-center
                    flex-wrap
                  "
                >
               
                  <a href="contacts.html" class="item-btn btn-fill">Commencer votre inscription</a>
            </div>
        </div>
    </div>
    </div>
    <div class="col-xl-4 col-md-6 col-12 masonry-item">
        <div class="course-box style-2">
            <div class="figure-box">
                <img src="media/etablissement/grace_divine.png" alt="Course" width="690" height="405" />
            </div>
            <div class="content-box">
                <div class="admin-figure">
                    <img src="media/responsable/divine.jpg" alt="Course" width="60" height="60" />
                </div>
                <div class="
                    mb-1
                    d-flex
                    justify-content-between
                    align-items-center
                    flex-wrap
                  ">

                    <ul class="rating inline-list">
                        <li><i class="fas fa-star"></i></li>
                        <li>5.0</li>
                    </ul>
                </div>
                <div class="teacher-name">
                    Directrice - <a href="profile.html">Madame Gossé</a>
                </div>
                <h3 class="title">
                    <a href="apropos.html">Le groupe scolaire GRACE DIVINE est un établissement d'excellence pour les études de vos enfants...</a >
         
                    </h3>
                <div
                  class="
                    d-flex
                    justify-content-between
                    align-items-center
                    flex-wrap
                  "
                >
               
                  <a href="contacts.html" class="item-btn btn-fill">Commencer votre inscription</a>
            </div>
        </div>
    </div>
    </div>
    <div class="col-xl-4 col-md-6 col-12 masonry-item">
        <div class="course-box style-2">
            <div class="figure-box">
                <img src="media/etablissement/grace_divine.png" alt="Course" width="690" height="405" />
            </div>
            <div class="content-box">
                <div class="admin-figure">
                    <img src="media/responsable/divine.jpg" alt="Course" width="60" height="60" />
                </div>
                <div class="
                    mb-1
                    d-flex
                    justify-content-between
                    align-items-center
                    flex-wrap
                  ">

                    <ul class="rating inline-list">
                        <li><i class="fas fa-star"></i></li>
                        <li>5.0</li>
                    </ul>
                </div>
                <div class="teacher-name">
                    Directrice - <a href="profile.html">Madame Gossé</a>
                </div>
                <h3 class="title">
                    <a href="apropos.html">Le groupe scolaire GRACE DIVINE est un établissement d'excellence pour les études de vos enfants...</a >
         
                    </h3>
                <div
                  class="
                    d-flex
                    justify-content-between
                    align-items-center
                    flex-wrap
                  "
                >
               
                  <a href="contacts.html" class="item-btn btn-fill">Commencer votre inscription</a>
            </div>
        </div>
    </div>
    </div>
    <div class="col-xl-4 col-md-6 col-12 masonry-item">
        <div class="course-box style-2">
            <div class="figure-box">
                <img src="media/etablissement/grace_divine.png" alt="Course" width="690" height="405" />
            </div>
            <div class="content-box">
                <div class="admin-figure">
                    <img src="media/responsable/divine.jpg" alt="Course" width="60" height="60" />
                </div>
                <div class="
            mb-1
            d-flex
            justify-content-between
            align-items-center
            flex-wrap
          ">

                    <ul class="rating inline-list">
                        <li><i class="fas fa-star"></i></li>
                        <li>5.0</li>
                    </ul>
                </div>
                <div class="teacher-name">
                    Directrice - <a href="profile.html">Madame Gossé</a>
                </div>
                <h3 class="title">
                    <a href="apropos.html">Le groupe scolaire GRACE DIVINE est un établissement d'excellence pour les études de vos enfants...</a >
 
            </h3>
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            flex-wrap
          "
        >
       
          <a href="contacts.html" class="item-btn btn-fill">Commencer votre inscription</a>
            </div>
        </div>
    </div>
    </div>
    <div class="col-xl-4 col-md-6 col-12 masonry-item">
        <div class="course-box style-2">
            <div class="figure-box">
                <img src="media/etablissement/grace_divine.png" alt="Course" width="690" height="405" />
            </div>
            <div class="content-box">
                <div class="admin-figure">
                    <img src="media/responsable/divine.jpg" alt="Course" width="60" height="60" />
                </div>
                <div class="
            mb-1
            d-flex
            justify-content-between
            align-items-center
            flex-wrap
          ">

                    <ul class="rating inline-list">
                        <li><i class="fas fa-star"></i></li>
                        <li>5.0</li>
                    </ul>
                </div>
                <div class="teacher-name">
                    Directrice - <a href="profile.html">Madame Gossé</a>
                </div>
                <h3 class="title">
                    <a href="apropos.html">Le groupe scolaire GRACE DIVINE est un établissement d'excellence pour les études de vos enfants...</a >
 
            </h3>
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            flex-wrap
          "
        >
       
          <a href="contacts.html" class="item-btn btn-fill">Commencer votre inscription</a>
            </div>
        </div>
    </div>
    </div>
    <div class="col-xl-4 col-md-6 col-12 masonry-item">
        <div class="course-box style-2">
            <div class="figure-box">
                <img src="media/etablissement/grace_divine.png" alt="Course" width="690" height="405" />
            </div>
            <div class="content-box">
                <div class="admin-figure">
                    <img src="media/responsable/divine.jpg" alt="Course" width="60" height="60" />
                </div>
                <div class="
            mb-1
            d-flex
            justify-content-between
            align-items-center
            flex-wrap
          ">

                    <ul class="rating inline-list">
                        <li><i class="fas fa-star"></i></li>
                        <li>5.0</li>
                    </ul>
                </div>
                <div class="teacher-name">
                    Directrice - <a href="profile.html">Madame Gossé</a>
                </div>
                <h3 class="title">
                    <a href="apropos.html">Le groupe scolaire GRACE DIVINE est un établissement d'excellence pour les études de vos enfants...</a >
 
            </h3>
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            flex-wrap
          "
        >
       
          <a href="contacts.html" class="item-btn btn-fill">Commencer votre inscription</a>
            </div>
        </div>
    </div>
    </div>
    <div class="col-xl-4 col-md-6 col-12 masonry-item">
        <div class="course-box style-2">
            <div class="figure-box">
                <img src="media/etablissement/grace_divine.png" alt="Course" width="690" height="405" />
            </div>
            <div class="content-box">
                <div class="admin-figure">
                    <img src="media/responsable/divine.jpg" alt="Course" width="60" height="60" />
                </div>
                <div class="
            mb-1
            d-flex
            justify-content-between
            align-items-center
            flex-wrap
          ">

                    <ul class="rating inline-list">
                        <li><i class="fas fa-star"></i></li>
                        <li>5.0</li>
                    </ul>
                </div>
                <div class="teacher-name">
                    Directrice - <a href="profile.html">Madame Gossé</a>
                </div>
                <h3 class="title">
                    <a href="apropos.html">Le groupe scolaire GRACE DIVINE est un établissement d'excellence pour les études de vos enfants...</a >
 
            </h3>
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            flex-wrap
          "
        >
       
          <a href="contacts.html" class="item-btn btn-fill">Commencer votre inscription</a>
            </div>
        </div>
    </div>
    </div> -->
    </div>
    <!-- <div class="pagination-style-1">
        <ul class="pagination">
            <li class="page-item">
                <a class="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">&laquo;</span>
                    <span class="sr-only">Previous</span>
                </a>
            </li>
            <li class="page-item"><a class="page-link" href="#">1</a></li>
            <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item">
                <a class="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">&raquo;</span>
                    <span class="sr-only">Next</span>
                </a>
            </li>
        </ul>
    </div> -->
    </div>
    </section>
    <!--=====================================-->
    <!--=     	  	  Blog End      	 	=-->
    <!--=====================================-->
    </div>
</template>
<script>
export default {
    methods: {
    async reload() {
      setTimeout(function(){
        window.location.reload(false);
      }, 20);
    }
  },
}
</script>