<template>

    <div>
        <div id="wrapper" class="wrapper">
            <!--=====================================-->
            <!--=     	 	Hero Start      		=-->
            <!--=====================================-->
            <section class="main-banner-wrap-style-2 motion-effects-wrap" data-bg-image="media/banner/bg1.jpg">
                <ul class="block-list shape-list">
                    <li class="el1 wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.1s">
                        <img class="tourne" src="media/elements/element21.png">
                    </li>
                    <li class="el2 wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.1s">
                        <img class="tourne" src="media/elements/element21.png">
                    </li>
                    <li class="shape-2 wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.1s">
                        <img src="media/banner/el1.svg">
                    </li>
                    <li class="shape-1 wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.1s">
                        <!-- <img class="motion-effects3" src="media/elements/element47.png" alt="Element" width="272" height="300" /> -->
                        <img src="media/banner/el2.svg">
                    </li>
                    <!-- <li class="shape-3 wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.1s">
                        <img class="" src="https://d1icd6shlvmxi6.cloudfront.net/gsc/HYBJPU/24/b2/2f/24b22fcbea5e4622bb64d69e7c0cd092/images/accueil_-_scem/vector_1_u213.svg?pageId=be3310a5-22b3-4a67-9f7f-563374b043f9">
                    </li> -->
                </ul>
                <div class="container">
                    <div class="row" style="justify-content: center">
                        <div class="col-lg-7">
                            <div class="main-banner-box style-2">
                                <div class="content-box">
                                    <div class="sub-title wow animated fadeInUp" data-wow-duration="1s" data-wow-delay="0.3s">
                                        <span>ScEM</span> School Expert Manager
                                    </div>
                                    <h1 class="title wow animate
                                    d fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                                        Inscrivez votre enfant rapidement et <span>sûrement</span>
                                    </h1>
                                    <p class="description wow animated fadeInUp" data-wow-duration="1s" data-wow-delay="0.7s">
                                        Découvrez ScEM on-line payment le premier moyen de paiement digital de frais scolaire en Guinée.
                                        Élaboré pour aider les parents d'élèves à inscrire leurs enfants en toute confiance et facilité, et à suivre leur évolution scolaire.
                                    </p>
                                    <div class="row wow animated fadeInUp" data-wow-duration="1s" data-wow-delay="0.9s" style="max-width: 590px; justify-content: center">
                                        <img class="wow  fadeInRight" data-wow-duration="1s" data-wow-delay="0.6s" src="media/elements/element63-2.png" alt="Elemet" width="62" height="55" style="margin-right: 10px; visibility: visible; animation-duration: 1s; animation-delay: 0.6s; animation-name: fadeInRight;">
                                        <a href="https://backoffice.preprod.scem-gn.com/#/login" class="item-btn btn-inscription-header">
                                            COMMENCER L'INSCRIPTION
                                        </a>
                                    </div>
                                    <!-- <div class="btn-wrap">
                                        <a href="https://backoffice.preprod.scem-gn.com/#/login" class="item-btn btn-fill">Devenir membre</a>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="apply-wrap-style-1 homebanner-video motion-effects-wrap">
                <div class="bg-no-repeat bg-position-center bg-size-contain" data-bg-image="media/banner/el3.svg" style="min-height :30em">
                    <div class="container">
                        <div class="row jc-center-banner">
                            <div class="col-lg-6 col-md-6 col-12">
                                <h1 class="h1-banner-homepage title wow animated fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                                    Simplifiez vous la vie avec l'utilisation de ScEM
                                </h1>
                            </div>
                            <div class="col-lg-6 col-md-6 col-12">
                                <a class="play-btn-video-banner popup-youtube wow zoomIn" data-wow-duration="1s" data-wow-delay="1s" href="https://www.youtube.com/watch?v=R8h73ZXY72E" style="visibility: visible; animation-duration: 1s; animation-delay: 1s; animation-name: zoomIn;"><i class="fas fa-play"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!--=====================================-->
            <!--=     	 	 Hero End       		=-->
            <!--=====================================-->

            <section style="padding-bottom: 10px !important;">
                <div class="container">
                    <div class="section-heading style-2">
                        <h2 class="box-etapes-title">
                            <span class="blue">Scem</span> en seulement <br>
                            <span class="green">4 étapes.</span>

                        </h2>
                    </div>
                </div>
            </section>
            <section class="main-banner-wrap-style-2 motion-effects-wrap" data-bg-image="media/banner/bg3.svg" style="padding-top: 0px !important; padding-bottom: 0px !important;">
                <ul class="block-list shape-list">
                    <li class="el1 wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.1s" style="left: 4em !important; top: 5em !important;">
                        <img class="tourne" style="width: 16em !important;" src="media/elements/element21.png">
                    </li>
                    <li class="el2 wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.1s" style="right: 3em !important; top: 25em !important;">
                        <img class="tourne" style="width: 16em !important;" src="media/elements/element21.png">
                    </li>
                    <li class="shape-3 wow animated fadeInRight" data-wow-duration="1s" data-wow-delay="0.1s">
                        <img src="media/banner/el4.png">
                    </li>
                </ul>
                <div class="container">
                    <div class="row jc-right">
                        <div class="col-xl-6 col-12 d-flex align-items-center">
                            <div class="row gutters-50">
                                <div class="col-md-6 col-12 child-item">
                                    <div class="feature-box style-2 color-1">
                                        <div class="content-box">
                                            <div class="icon-box" style="width: 60% !important;">
                                                <img src="media/home/element1.png">
                                                <!-- <i class="flaticon-teacher-giving-lecture"></i> -->
                                            </div>
                                            <h3 class="title">Connexion</h3>
                                            <p class="description">
                                                Devenez membre en créant votre compte.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 child-item">
                                    <div class="feature-box style-2 color-2">
                                        <div class="content-box">
                                            <div class="icon-box" style="width: 60% !important;">
                                                <img src="media/home/element2.png">
                                                <!-- <i class="flaticon-shopping-list"></i> -->
                                            </div>
                                            <h3 class="title">Demande</h3>
                                            <p class="description">
                                                Faites une demande d'inscription.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 child-item">
                                    <div class="feature-box style-2 color-3">
                                        <div class="content-box">
                                            <div class="icon-box" style="width: 60% !important;">
                                                <img src="media/home/element3.png">
                                                <!-- <i class="flaticon-alarm-clock"></i> -->
                                            </div>
                                            <h3 class="title">Inscription</h3>
                                            <p class="description">
                                                Inscrivez vous dès la validation de votre demande. 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 child-item">
                                    <div class="feature-box style-2 color-4">
                                        <div class="content-box">
                                            <div class="icon-box" style="width: 60% !important;">
                                                <img src="media/home/element4.png">
                                                <!-- <i class="flaticon-manufacturing"></i> -->
                                            </div>
                                            <h3 class="title">Paiement</h3>
                                            <p class="description">
                                                Réglez vos frais scolaires par Mobile Money. 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="">
                <div class="row" style="justify-content: space-between; background-color:#e9fcea; height: 890px;">
                    <div class="col-xl-7 col-12 row jc-end" style="justify-content: end;">
                        <div class="col-xl-8 col-12 align-items-center">
                            <div class="section-heading style-6">
                                <h2 class="title">Etapes d'inscription</h2>
                                <p class="description">
                                    Comment s'inscrire ? Suivez juste ces étapes ci-dessous.
                                    En cas de préoccupation contactez le service client.
                                </p>
                            </div>
                            <div class="row gutters-50">
                                <div class="col-md-6 col-12 child-item">
                                    <div class="feature-box style-I color-1">
                                        <div class="content-box">
                                            <div class="">
                                                <img src="media/home/fig1.png" style="width: 30%;">
                                            </div>
                                            <h3 class="title">Demande d’inscription</h3>
                                            <p class="description">
                                                Créez votre compte puis faites une demande d'inscription à l'établissement de votre choix.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 child-item">
                                    <div class="feature-box style-I color-2">
                                        <div class="content-box">
                                            <div class="">
                                                <img src="media/home/fig2.png" style="width: 30%;">
                                            </div>
                                            <h3 class="title">Inscription</h3>
                                            <p class="description">
                                                Après validation de votre demande d'inscription. Allez dans le menu inscription. Entrez votre code ScEM.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 child-item">
                                    <div class="feature-box style-I color-3">
                                        <div class="content-box">
                                            <div class="">
                                                <img src="media/home/fig3.png" style="width: 30%;">
                                            </div>
                                            <h3 class="title">Informations</h3>
                                            <p class="description">
                                                Renseignez les différentes rubriques selon le ou les paiements à effectuer. Vous pouvez choisir plusieurs rubriques. 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 col-12 child-item">
                                    <div class="feature-box style-I color-4">
                                        <div class="content-box">
                                            <div class="">
                                                <img src="media/home/fig4.png" style="width: 30%;">
                                            </div>
                                            <h3 class="title">Paiement</h3>
                                            <p class="description">
                                                Paiement via votre compte mobile Orange ou MTN. Des frais de service sont prélevés en fonction du paiement.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="col-xl-5 col-12 bg-no-repeat bg-size-cover" data-bg-image="media/banner/bloc4.jpg" style="border-radius: 10px 0px 0px 10px;"></div>
                </div>
            </div>

        </div>

        <section class="apply-wrap-style-1 container motion-effects-wrap">
            <div class="bg-no-repeat bg-position-center bg-size-cover" data-bg-image="media/banner/partenaires.png" style="border-radius: 10px;">
                <div class="container" style="background-color: rgba(31, 130, 164, 0.8); border-radius: 10px; padding: 0px 40px 0px 40px;">
                    <div class="row jc-center-banner">
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="section-heading style-I animated fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                                <h2 class="title">NOS PARTENAIRES</h2>
                                <p class="description">
                                    
                                </p>
                            </div>
                            <hr>
                            <div class="section-heading style-I animated fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                                <h2 class="title">N'attendez plus et n'hésitez pas! </h2>
                                <p class="description mb-4">
                                    Soyez les premiers membre de ScEM à profiter de ses services. Découvrez des offres spéciales, des promos, et bien d'autres surprises...
                                </p>
                                <a href="office_url" class="item-btn btn-inscription-membre"> COMMENCER L'INSCRIPTION </a>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-12">
                            <div class="row justify-content-center align-items-center">
                                <div
                                class="
                                    col-xl-5 col-md-3 col-sm-4 col-6
                                    wow
                                    fadeInRight
                                    animated
                                "
                                data-wow-duration="1s"
                                data-wow-delay="0.1s"
                                >
                                <div class="brand-box style-1">
                                    <div class="figure-box">
                                    <img
                                        src="media/partenaire/Orange.png"
                                        style="border-radius: 10px;"
                                        alt="Brand"
                                        width="135"
                                        height="135"
                                    />
                                    <span class="brand-name">ORANGE</span>
                                    </div>
                                </div>
                                </div>
                                <div
                                class="col-xl-5 col-md-3 col-sm-4 col-6 wow fadeInUp animated"
                                data-wow-duration="1s"
                                data-wow-delay="0.2s"
                                >
                                <div class="brand-box style-1">
                                    <div class="figure-box">
                                    <img
                                        src="media/partenaire/MTN.png"
                                        style="border-radius: 10px;"
                                        alt="Brand"
                                        width="150"
                                        height="150"
                                    />
                                    <span class="brand-name">MTN</span>
                                    </div>
                                </div>
                                </div>
                                <!-- <div
                                    class="
                                        col-xl-5 col-md-3 col-sm-4 col-6
                                        wow
                                        fadeInLeft
                                        animated
                                    "
                                    data-wow-duration="1s"
                                    data-wow-delay="0.3s"
                                >
                                    <div class="brand-box style-1">
                                        <div class="figure-box">
                                        <img
                                            src="media/partenaire/UBA.png"
                                            style="border-radius: 10px;"
                                            alt="Brand"
                                            width="135"
                                            height="135"
                                        />
                                        <span class="brand-name">UBA</span>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                            <!-- <a class="play-btn-video-banner popup-youtube wow zoomIn" data-wow-duration="1s" data-wow-delay="1s" href="https://www.youtube.com/watch?v=R8h73ZXY72E" style="visibility: visible; animation-duration: 1s; animation-delay: 1s; animation-name: zoomIn;"><i class="fas fa-play"></i></a> -->
                        </div>
                    </div>
                </div>
            </div>
        </section>



        <!-- Footer -->
        <footer class="footer style-2">
            <div class="container">
                <div class="footer-top">
                <div class="row">
                    <div class="col-lg-3 col-md-6 col-12">
                    <div class="widget widget-contact">
                        <div class="footer-logo" style="position: absolute; top: -60px;">
                        <a href="" class="d-inline-block"><img src="media/SCEM-logo.png" alt="Logo" width="100" height="37" /></a>
                        </div>
                        <div class="footer-address">
                        Kipé, Guinée-Conakry
                        </div>
                        <div class="footer-number">
                        <i class="fas fa-phone-alt"></i> +224 628 88 99 99
                        </div>
                        <div class="footer-number">
                        <i class="fas fa-phone-alt"></i> +224 664 22 77 11
                        </div>
                        <div class="footer-email">
                        placidegosse@scem-gn.com
                        </div>
                    </div>
                    </div>


                    <div class="col-lg-3 col-md-6 col-12">
                    <div class="widget widget-nav style-1">
                        <ul class="block-list menu">
                        <li class="cursor menu-item"><a @click.prevent="reload()" @click="$router.push({name: 'Home'})">Accueil</a></li>
                        <li class="cursor menu-item"><a @click.prevent="reload()" @click="$router.push({name: 'Etablissements'})"> Etablissements </a></li>
                        <!-- <li class="menu-item"><a href="#">Nos Partenaires</a></li>
                        <li class="menu-item"><a href="#">Instructor</a></li> -->
                        </ul>
                    </div>
                    </div>
                    <br>
                    <br>
                    <br>
                    <br>
                    <div class="col-lg-3 col-md-6 col-12">

                    <div class="widget widget-nav style-1">
                        <ul class="block-list menu">
                        <li class="cursor menu-item"><a @click.prevent="reload()" @click="$router.push({name: 'Contacts'})">Contacts</a></li>
                        <li class="cursor menu-item"><a @click.prevent="reload()" @click="$router.push({name: 'Apropos'})">A propos</a></li>
                        <!-- <li class="menu-item">
                            <a href="#">Commencer mon inscription</a>
                        </li>
                        <li class="menu-item">
                            <a href="#">Politique &amp; de confidentialité</a>
                        </li> -->

                        </ul>
                    </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="widget widget-subscribe">
                            <div class="footer-subscribe">
                            <h4 class="description">
                                Nous faire un mail
                            </h4>
                            <form method="post" action="mailto:placidegosse@scem-gn.com">
                                <button class="submit btn-Begin-inscritption">
                                Envoyer un mail
                                <!-- <a v-bind:href="'mailto:' + person.email">{{ person.email }}</a> -->
                                </button>
                            </form>
                            </div>
                        </div>
                        <ul data-v-1ed7b823="" class="inline-list footer-social">
                            <li data-v-1ed7b823="">
                                <a data-v-1ed7b823="" href="https://www.facebook.com/SCEM-108062061610379/" target="_blank" class="facebook">
                                    <i data-v-1ed7b823="" class="fab fa-facebook-f" style="color: midnightblue;"></i>
                                </a>
                            </li>
                            <li data-v-1ed7b823="">
                                <a data-v-1ed7b823="" href="https://www.youtube.com/channel/UCZjZlvHmePxCKc86f_LxpxQ" target="_blank" class="youtube">
                                    <i data-v-1ed7b823="" class="fab fa-youtube" style="color: red;"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                </div>
                
            </div>
        </footer>
    </div>
    
</template>

<script>
export default {
    methods: {
    async reload() {
      setTimeout(function(){
        window.location.reload(false);
      }, 20);
    }
  },
  data() {
    return {
        office_url:""
    }
  },
  mounted() {
    this.office_url = process.env.BACK_OFFICE_URL
  },
}
</script>

<style scoped>
</style>