<template>
    <footer class="footer style-2">
        <div class="shap-wrap">
            <img class="wow fadeInUpBig animated" data-wow-duration="1.5s" data-wow-delay="0.2s" src="media/elements/element64.png" alt="Element" width="1920" height="517" />
        </div>

        <div class="footer-bottom">
            <div class="container">
                <div class="row">
                    <div class="
                        col-md-3
                        d-flex
                        justify-content-center justify-content-md-start
                    ">
                        <div class="footer-logo">
                            <a href="index.html" class="d-inline-block"><img src="scem.png" alt="Logo" width="60" height="30" /></a>
                        </div>
                    </div>
                    <div class="
                        col-md-9
                        d-flex
                        justify-content-center justify-content-md-end
                        align-items-center
                    ">
                        <div class="footer-copyright">
                            © 2022 <span>Scem</span> Tous droits réservés
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
  name: 'Foooter',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>