<template>
  <div id="wrapper" class="wrapper">
      <!--=====================================-->
      <!--=     		Header Start     		=-->
      <!--=====================================-->
      <header class="header-style-2 sticky-on">
          <div id="navbar-wrap" class="navbar-wrap">
            <div class="header-menu">
              <div class="container">
                <div class="d-flex align-items-center justify-content-between" style="height: 80px;">
                  <div class="cursor site-branding">
                      <a @click.prevent="reload()" @click="$router.push({name: 'Home'})" class="logo"><img src="media/SCEM-logo.png" alt="Logo" width="80" height="46" /></a>
                  </div>
                  <nav class="template-main-menu">
                    <ul class="menu">
                      <li class="cursor menu-item menu-item-has-children">
                        <a @click.prevent="reload()" @click="$router.push({name: 'Home'})">Accueil</a>
                      </li>
                      <li class="cursor menu-item menu-item-has-children">
                        <a @click.prevent="reload()" @click="$router.push({name: 'Etablissements'})"> Etablissements </a>
                      </li>

                      <li class="cursor menu-item menu-item-has-children">
                        <a @click.prevent="reload()" @click="$router.push({name: 'Contacts'})">Contacts</a>
                      </li>
                      <li class="cursor menu-item menu-item-has-children">
                        <a @click.prevent="reload()" @click="$router.push({name: 'Apropos'})">A propos</a>
                      </li>

                    </ul>
                  </nav>
                  <nav class="template-main-menu">
                    <ul class="menu">
                      <!-- <li class="menu-item menu-item-has-children">
                        <a href="inscription.html" class="btn-inscription">Inscription</a>
                        <ul class="sub-menu">
                          <li class="menu-item">
                            <a href="#">Demande Inscription</a>
                          </li>
                          <li class="menu-item">
                            <a href="#">Réinscription</a>
                          </li>
                        </ul>
                      </li> -->

                      <li class="menu-item menu-item-has-children ">
                          <a href="https://backoffice.preprod.scem-gn.com/#/login" class="btn-connexion-header">Connexion</a>
                      </li>

                    </ul>
                  </nav>
                  <!-- <ul class="header-action-items inline-list">
                    <li class="search-wrap style-3">
                      <a href="#template-search" class="item-icon" title="Search">
                        <i class="fas fa-search"></i>
                      </a>
                    </li>
                  </ul> -->
                </div>
              </div>
            </div>
          </div>
      </header>
      <div class="rt-header-menu mean-container position-relative" id="meanmenu">
          <div class="cursor mean-bar">
              <a @click.prevent="reload()" @click="$router.push({name: 'Home'})">
                  <img src="scem.png" alt="Logo" width="50" height="29" />
              </a>
              <div class="mean-bar--right">
                  <!-- <div class="actions search">
                      <a href="#template-search" class="item-icon" title="Search">
                          <i class="fas fa-search"></i>
                      </a>
                  </div> -->
                  <!-- <div class="actions user">
                      <a href="profile.html"><i class="flaticon-user"></i></a>
                  </div> -->
                  <div></div>
                  <span class="sidebarBtn">
                    <span class="bar"></span>
                    <span class="bar"></span>
                    <span class="bar"></span>
                    <span class="bar"></span>
                  </span>
              </div>
          </div>
          <div class="rt-slide-nav">
              <div class="offscreen-navigation">
                  <nav class="menu-main-primary-container">
                      <ul class="menu">
                          <li class="cursor"> <a @click.prevent="reload()" @click="$router.push({name: 'Home'})">Accueil</a> </li>
                          <li class="cursor"> <a @click.prevent="reload()" @click="$router.push({name: 'Etablissements'})"> Etablissements</a> </li>
                          <li class="cursor"> <a @click.prevent="reload()" @click="$router.push({name: 'Contacts'})"> Contacts</a></li>
                          <li class="cursor"> <a @click.prevent="reload()" @click="$router.push({name: 'Apropos'})"> A propos</a> </li>
                          <!-- <li class="list menu-item-parent menu-item-has-children">
                              <a class="animation">Inscription</a>
                              <ul class="main-menu__dropdown sub-menu">
                                  <li><a href="index.html">Demande Inscription</a></li>
                                  <li><a href="index.html">Réinscription</a></li>
                              </ul>
                          </li> -->

                          <li class="menu-item">
                            <a href="office_url" class="btn-green" style="background-color: #32ac46;">Connexion</a>
                          </li>

                      </ul>
                  </nav>
              </div>
          </div>
      </div>
      <!--=====================================-->
      <!--=     	 	Header End      		=-->
      <!--=====================================-->
  </div>
</template>
<script>
export default {
name: 'Heeader',
  methods: {
    async reload() {
      setTimeout(function(){
        window.location.reload(false);
      }, 20);
    }
  },
  data() {
    return {
      office_url:""
    }
  },

  mounted() {
    this.office_url = process.env.BACK_OFFICE_URL
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>