import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../Pages/Home.vue'
import Etablissements from '../Pages/Etablissements.vue'
import Contacts from '../Pages/Contacts.vue'
import Apropos from '../Pages/Apropos.vue'
import Gracedivine from '../Pages/Schools/Gracedivine.vue'
import Isthog from '../Pages/Schools/Isthog.vue'
import Eror from '../components/404.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/etablissements',
    name: 'Etablissements',
    component: Etablissements
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts
  },
  {
    path: '/apropos',
    name: 'Apropos',
    component: Apropos
  },
  {
    path: '/school-gracedivine',
    name: 'Gracedivine',
    component: Gracedivine
  },
  {
    path: '/school-Isthog',
    name: 'Isthog',
    component: Isthog
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: Eror
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
