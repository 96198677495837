<template>
    <div style="background-color: rgb(242, 252, 255);">
        <!-- <div class="breadcrumb-wrap-style-2" data-bg-image="media/contacts/banniere.png">
            <div class="inner-banner-title">
                <h1 class="title">Contactez nous!</h1>
            </div>
        </div> -->
        <!--=====================================-->
        <!--=     	 	Contact Start     		=-->
        <!--=====================================-->
        <section class="container" style="max-width: 1300px !important;">
            <div class="contacts-wrap-style-1">
                <div class="row">

                    <div class="col-lg-5 col-md-12">
                        <div class="contacts-form style-1">
                            <div class="inner">
                                <h2 class="title">Contactez-nous</h2>
                                <!-- <p class="description">
                                    Votre adresse email ne sera pas publiée.
                                </p> -->
                                <form id="contacts-form">
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <input type="text" placeholder="Votre Nom" class="form-control" name="name" data-error="Name field is required" required />
                                            <div class="help-block with-errors"></div>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <input type="email" placeholder="Votre Email*" class="form-control" name="email" data-error="Email field is required" required />
                                            <div class="help-block with-errors"></div>
                                        </div>
                                        <div class="col-12 form-group">
                                            <textarea placeholder="Commentaire" class="textarea form-control" name="message" id="form-message" rows="6" cols="20" data-error="Message field is required" required></textarea>
                                            <div class="help-block with-errors"></div>
                                        </div>
                                        <div class="col-12 form-group text-center">
                                            <button type="submit" class="btn-Fiche-renseignement">
                                                ENVOYER
                                            </button>
                                        </div>
                                    </div>
                                    <div class="form-response"></div>
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-12">
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="address-box style-1">
                                    <div class="icon-box">
                                        <i class="flaticon-telephone"></i>
                                    </div>
                                    <div class="content-box">
                                        <label>Appelez-nous</label>
                                        <h3 class="title">+224 628 88 99 99</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="address-box style-1">
                                    <div class="icon-box">
                                        <i class="flaticon-email"></i>
                                    </div>
                                    <div class="content-box">
                                        <label>Envoyez-nous un email</label>
                                        <h3 class="title" style="font-size: 15px">placidegosse@scem-gn.com</h3>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="address-box style-1">
                                    <div class="icon-box">
                                        <i class="flaticon-pin"></i>
                                    </div>
                                    <div class="content-box">
                                        <label>Addresse</label>
                                        <h3 class="title">Kipé, Guinée-Conakry</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="map-box style-1 mt-5" data-lat="9.60647777337" data-lng="-13.639986440479959" data-mrkr="media/map-marker.png"></div>

                    </div>

                </div>
            </div>
        </section>
        <!--=====================================-->
        <!--=     	 	Contact End     		=-->
        <!--=====================================-->
    </div>
</template>