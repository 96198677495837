<template>
    <div style="background-color: #f2fcff;">

      <!--=====================================-->
      <!--=     	 	Hero Start      		=-->
      <!--=====================================-->
        <section class="main-banner-wrap-style-3 motion-effects-wrap">
            <div class="right-full-width">
            <div class="main-banner-box style-3">
                <div class="figure-box order-lg-2">
                <div
                    class="wow fadeInRightTopMedium animated"
                    data-wow-duration="1s"
                    data-wow-delay="0.1s"
                >
                    <img
                    src="media/banner/banner10.png"
                    alt="Banner"
                    width="1200"
                    height="1108"
                    />
                    <a
                    class="play-btn-video-banner popup-youtube wow zoomIn animated"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                    href="https://www.youtube.com/watch?v=R8h73ZXY72E"
                    ><i class="fas fa-play"></i
                    ></a>
                </div>
                <ul class="block-list shape-list">
                    <li
                    class="shape1 wow fadeInDown animated"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                    >
                    <img
                        class="motion-effects1"
                        src="media/SCEM-logo.png"
                        alt="Element"
                        width="131"
                        height="109"
                    />
                    </li>
                    <li
                    class="shape2 wow fadeInLeft animated"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                    >
                    <img
                        class="motion-effects3"
                        src="media/elements/element42.png"
                        alt="Element"
                        width="228"
                        height="228"
                    />
                    </li>
                    <li
                    class="shape3 wow fadeInLeft animated"
                    data-wow-duration="1s"
                    data-wow-delay="0.7s"
                    >
                    <img
                        class="motion-effects2"
                        src="media/elements/element43.png"
                        alt="Element"
                        width="456"
                        height="529"
                    />
                    </li>
                    <li
                    class="shape4 wow fadeInUp animated"
                    data-wow-duration="1s"
                    data-wow-delay="0.9s"
                    >
                    <img
                        class="motion-effects4"
                        src="scem.png"
                        alt="Element"
                        width="100"
                        height="100"
                    />
                    </li>
                </ul>
                </div>
                <div class="content-box order-lg-1">
                <div
                    class="sub-title wow fadeInUp animated"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                >
                    A propos de ScEM
                </div>
                <h1
                    class="title wow fadeInUp animated"
                    data-wow-duration="1s"
                    data-wow-delay="1.2s"
                >
                    ScEM….UNE VISION
                </h1>
                <p
                    class="description wow fadeInUp animated"
                    data-wow-duration="1s"
                    data-wow-delay="1.4s"
                >
                    School Expert Manager (ScEM) est une plateforme créée avec une vision, celle de promouvoir l’excellence dans le domaine de l’éducation en Guinée.
                    Dans un premier temps pour aider les établissements scolaires à gérer leur administration et surtout le flux financier lié aux frais scolaires.
                    ScEM compte s’étendre à d’autres domaines de l’éducation à savoir faciliter l’éducation des élèves dans les écoles de leur choix,
                    les collaborations inter-établissements de divers région etc.
                </p>
                </div>
            </div>
            </div>
        </section>
      <!--=====================================-->
      <!--=     	 	 Hero End       		=-->
      <!--=====================================-->

        <section class="container" style="padding-top: 0px !important;">
            <h3 class="h3-title">La stratégie ScEM se décline autour de trois axes</h3>
            <div class="row">
                <div class="col-lg-4">
                    <div class="address-box style-1 mt-0" style="box-shadow: 0px 0px 0px 7px rgb(241 246 248);">
                        <div class="icon-box" style="height: 70px; width: 70px; background-color: #9ae5ff;">
                            <span style="font-weight: 500; color: #1f82a4; font-size: 40px">1</span>
                        </div>
                        <div class="content-box">
                            <h3 class="h3-title mb-0">ScEM One-line payment</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="address-box style-1 mt-0" style="box-shadow: 0px 0px 0px 7px rgb(241 246 248);">
                        <div class="icon-box" style="height: 70px; width: 70px; background-color: #37b4f0;">
                            <span style="font-weight: 500; font-size: 40px">2</span>
                        </div>
                        <div class="content-box">
                            <h3 class="h3-title mb-0">ScEM Interactivity</h3>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="address-box style-1 mt-0" style="box-shadow: 0px 0px 0px 7px rgb(241 246 248);">
                        <div class="icon-box" style="height: 70px; width: 70px; background-color: #2482a4;">
                            <span style="font-weight: 500; font-size: 40px">3</span>
                        </div>
                        <div class="content-box">
                            <h3 class="h3-title mb-0">ScEM Vision of Excellence</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section style="padding-top : 0px">
            <div class="container apropos-wrap">

                <div class="blog-content-box mb-5">
                    <h2 class="main-title">
                        1) ScEM One-line payment
                    </h2>
                    <p>
                        <strong>School Expert Manager (ScEM)</strong> on-line payment est un système de paiement en ligne pour les différents frais d’écolage via la plateforme.
                        La différence avec les systèmes classiques de paiement, c’est que La plateforme ScEM délivre automatiquement un reçu électronique détaillé et au parent d’élève initiant la transaction et à l’établissement scolaire concerné.
                        Lesquelles reçus sont imprimables. En outre, ScEM on-line payment vient résoudre un chapelet de difficultés dans le processus des inscriptions et de paiements de frais scolaires La plateforme vient mettre fin :
                    </p>
                    <p>
                        <ul class="block-list list-info">
                            <li> Aux encombrements dans les établissements scolaires ou bancaires et pertes de temps énorme qui en résulte.</li>
                            <li> Aux trajets et ce que sa coûte pour le paiement de frais scolaires.</li>
                            <li> Aux gros problèmes de trésorerie que rencontrent les établissements scolaires.</li>
                            <li> Aux incompréhensions « administrations-parents d’élèves » sur l’effectivité des paiements.</li>
                            <li> Aux faux billets dissimuler dans les liasses et aux infections liées à la manipulation des billets de banque.</li>
                        </ul>
                    </p>

                </div>

                <div class="blog-content-box mb-5">
                    <h2 class="main-title">
                        2) ScEM Interactivity
                    </h2>
                    <p>
                        <strong>School Expert Manager (ScEM)</strong> est une plateforme créée pour promouvoir un meilleur encadrement des élèves
                        à travers un suivi interactif bilatéral « parents d’élèves - administrations scolaires ».
                        Via la plateforme ScEM, les parents pourront :
                    </p>
                    <p>
                        <ul class="block-list list-info">
                            <li> Consulter les notes de leurs enfants, avoir leurs bulletins et les remarques des enseignants.</li>
                            <li> Apporter des avis, des remarques et des suggestions sur des sujets pertinents.</li>
                            <li> Suivre l’actualité Générale de l’établissement de leurs enfants.</li>
                        </ul>
                    </p>
                    <p>
                        Les établissements scolaires ne seront plus isolés pour ce qui concerne l’encadrement et le suivi de nos enfants.
                    </p>

                </div>

                <section class="apropos-wrap mb-5" style="padding-top: 0px; padding-bottom: 0px;">
                    <div class="main-figure-box">
                        <img src="media/apropos/vision.jpg" alt="Une vision" />
                    </div>
                </section>






                <div class="blog-content-box mb-5">
                    <h2 class="main-title">
                        3) ScEM vision of Excellence
                    </h2>
                    <p>
                        <strong>School Expert Manager (ScEM)</strong> est une vision, celle de promouvoir l’excellence dans le domaine de l’éducation en Guinée.
                        Nous voulons rehausser l’image de l’école guinéenne en utilisant des techniques de thérapie participative.
                    </p>
                    <p> <strong> 1) L’introspection </strong></p>
                    <p>
                        Via la plateforme ScEM nous voulons que les écoles partenaires se retrouvent face à elles-mêmes, à leurs forces et faiblesses,
                        afin qu’elles s’inscrivent dans un élan de créativité pour rehausser leur image dans l’environnement de l’éducation, en travaillant
                        sur la qualité des cours dispensée, l’organisation académique, la gestion administrative etc... ScEM s’impliquera dans divers concours et prix pour stimuler la quête de l’excellence en milieu scolaire.
                    </p>
                    <p>
                        <ul class="block-list list-info">
                            <li> Prix de l’école la plus propre.</li>
                            <li> Prix de l’école la plus belle.</li>
                            <li> Prix du meilleur élève en classe d’examen.</li>
                            <li> Prix du meilleur management.</li>
                            <li> Prix du meilleur enseignant…</li>
                        </ul>
                    </p>
                    <p> <strong> 2) La Sublimation </strong> </p>
                    <p>
                        Cette technique est de promouvoir une meilleure opinion de l’école guinéenne de façon générale.
                        La plateforme présentera des témoignages et conseils de personnalités qui ont hisser haut le drapeau guinéen sur le plan de l’éducation pour permettre
                        à la jeune génération d’avoir de bons repères, de rejeter la médiocrité pour s’inscrire dans la quête de l’excellence.
                        ScEM compte avec l’appui d’institutions, de structures et personnalités de bonne volonté, en vue d’organiser des rubriques et campagnes de sensibilisations.
                        Touchant toutes les couches du système de l’éducation.
                        ScEM mettra sur pied une équipe privée de réflexion pour trouver des voies et moyens efficaces pour tirer l’école de Guinée vers le haut.
                    </p>
                    <h1>
                        <strong> ScEM… Just free your Mind </strong>
                    </h1>


                </div>

            </div>
        </section>
    </div>
</template>
