<template>
    <div style="background-color:#f2fcff;">
        <!--=====================================-->
        <!--=       	 Header Début      	=-->
        <!--=====================================-->
        <div class="breadcrumb-wrap-style-2" data-bg-image="media/banner/schoolsBanner.png">
            <div class="container">
                <div class="row">
                    <div class="inner-banner-title col-lg-10 col-xs-12" style="padding-top: 30px;">
                        <h1 class="title mb-3">GROUPE SCOLAIRE GRACE DIVINE</h1>
                        <p class="subtitle">Décrouvrez les établissements enregistés sur SCEM</p>
                    </div>
                    <div class="inner-banner-title col-lg-2 col-xs-12" style="text-align: center;">
                        <img src="media/banner/iconschool.svg" alt="" style="width: 110px; height: 90px;" class="mb-3">
                        <a class="">ÉCOLE PRIMAIRE</a>
                    </div>
                </div>
            </div>
        </div>
        <!--=====================================-->
        <!--=     	 	 Header Fin    		=-->
        <!--=====================================-->
        <!--=====================================-->
        <!--=         Infos page Début     	=-->
        <!--=====================================-->
        <section class="apropos-wrap" style="padding-top: 15px;">
            <div class="container">
                <div class="row mt-5 mb-3">
                    <div class="col-md-6 col-12">
                        <div class="inner-figure-box">
                            <img src="media/etablissement/classe2.jpg" alt="Blog" width="570" height="390" />
                        </div>
                    </div>
                    <div class="col-md-6 col-12">
                        <h3 class="title">
                            DESCRIPTION & RENSEIGNEMENTS
                        </h3>
                        <p class="description mb-5">
                            Le Groupe scolaire Grace Divine est un établissement d’excellence pour les études de vos enfants. Il dispose d’un programme harmonisé en Français /Anglais, des enseignants sérieux, expérimentés et rigoureux de  diverses nationalités.  
                            L’établissement vous offre une formation de qualité dans un cadre spacieux, très accueillant comportant des  classes aérées.
                        </p>
                        <div class="d-flex mb-4" style="justify-content: space-between;">
                            <a href="https://backoffice.preprod.scem-gn.com/#/login" class="btn-Begin-inscritption" tabindex="-1">COMMENCER L'INSCRIPTION</a>
                            <a href="https://drive.google.com/drive/folders/1csqw8vJV0sGcnQMmAbMH1k4v20tAeUns?usp=sharing" target="_blank" class="btn-Fiche-renseignement" tabindex="-1">
                                FICHE DE RENSEIGNEMENTS
                                <i class="far fa-file-alt"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="row mt-5 mb-3">
                        <div class="col-md-6 col-12">
                            <div class="blog-content-box">
                                <h2 class="main-title">
                                    INFORMATIONS D’INSCRIPTION 
                                </h2>
                                <h5 class="inner-title mb-0"> FRAIS D’INSCRIPTION : 190 000 GNF </h5>
                                <h5 class="inner-title"> FRAIS DE REINSCRIPTION : 180 000 GNF </h5>
                                <p>
                                    <span>
                                        Nous disposons au sein de l’établissement de : 
                                        <ul class="block-list list-info mt-3">
                                            <li> Tickets de cantines (FACULTATIF)</li>
                                            <li> La cantine au sein de l’établissement est à 150 000 / 2 semaines soit 300 000 / MOIS</li>
                                            <li> Tenues des élèves de la maternelle (OBLIGATOIRE)</li>
                                            <li> Tenues des filles du primaire (OBLIGATOIRE)</li>
                                            <li> Tenues de sport (OBLIGATOIRE) </li>
                                        </ul>
                                        NB : Le port des chaussures fermées et des uniformes scolaires décents est strictement exigé pour tous les élèves  de l’établissement.

                                        <h5 class="inner-title mt-4">
                                            DOSSIER A FOURNIR POUR L’INSCRIPTION
                                        </h5>
                                        <span class="sub-info"> MATERNELLE PRIMAIRE ET COLLEGE  </span>

                                        <ul class="block-list list-info mt-3">
                                            <li> 6 photos d’identité 6 photos d’identité</li>
                                            <li> 1 copie de la pièce d’identité de l’un des parents</li>
                                            <li> 1 copie de l’extrait de naissance</li>
                                            <li> 1 copie du carnet de vaccination </li>
                                            <li> 1 livret scolaire</li>
                                        </ul>
                                            
                                            
                                        Y ajouter une attestation d’admission à l’entrée en 7ème HEURES DE COURS
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div class="col-md-6 col-12">
                            <div class="blog-content-box">
                                <h2 class="main-title text-center">
                                    HEURES DE COURS
                                </h2>
                                <p>
                                    <span>
                                        <h5 class="inner-title"> MATERNELLE </h5> 
                                        <span class="sub-info">LUNDI AU JEUDI :</span> <strong> 08H – 14H00 </strong> <br>
                                        <span class="sub-info">VENDREDI :</span> <strong> 08H – 12H00 </strong>

                                        <h5 class="inner-title mt-4"> PRIMAIRE </h5>
                                        <span class="sub-info">LUNDI AU JEUDI :</span> <strong> 08H – 17H00 </strong> <br>
                                        <span class="sub-info">VENDREDI :</span> <strong> 08H – 15H00 </strong> <br>
                                        <span class="sub-info">COURS DE RENFORCEMENT LUNDI AU JEUDI:</span> <strong> 14H30 à 16H00 </strong>

                                        <h5 class="inner-title mt-4"> PRIMAIRE </h5>
                                        <span class="sub-info">LUNDI AU VENDREDI :</span> <strong> 08H – 14H00 </strong> <br>
                                        <span class="sub-info">VENDREDI :</span> <strong> 08H – 15H00 </strong> <br>
                                        <span class="sub-info">COURS DE RENFORCEMENT LUNDI AU JEUDI:</span> <strong> 14H30 à 16H00 </strong>
                                    </span>
                                </p>
                                <p>
                                    NB : les cours de renforcement sont <strong> obligatoires </strong> pour les élèves du primaire et se tiendront de <strong> 14H30 à 16H00 </strong>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-12 mt-5" style="padding: 0px;">
                        <div class="blog-content-box">
                            <h2 class="main-title">
                                LES INSCRIPTIONS ET REINSCRIPTIONS ONT DEBUTE DES LE 1ER SEPTEMBRE
                            </h2>
                                <div class="" style="display: flex;">
                                    <div class="icon-box mr-3">
                                        <i class="flaticon-pin"></i>
                                    </div>
                                    <p>
                                        <span class="sub-info">
                                            GROUPE SCOLAIRE GRACE DIVINE SIS A KIPE CENTRE EMETTEUR 
                                            Le Groupe Scolaire Grace Divine est situé au centre émetteur sur la transversale bambéto – Kipé à 100 mètres de SEVEN ELEVEN <br>
                                        </span>
                                    </p>
                                </div>
                            <h4 class="text-font"> Contacts : 662 28 38 04 / 628 23 03 87 </h4>
                            <strong class="text-font" style="color:  #32ac46;"> NB : TOUT PAIEMENT SE FAIT SUR LA PLATEFORME SCEM </strong>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!--=====================================-->
        <!--=      	    Infos page Fin      	=-->
        <!--=====================================-->
    </div>
</template>