<template>
  <div id="app">
    <Heeader/>
    <router-view/>
    <Foooter/>
  </div>
</template>

<script>
import Heeader from './components/Heeader.vue'
import Foooter from './components/Foooter.vue'

export default {
  name: 'App',
  components: {
    Heeader,
    Foooter,
  }
}
</script>

<style scoped>

 /* .footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0
  } */

</style>
