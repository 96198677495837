<template>
    <div>
        <!--=====================================-->
        <!--=       	 Breadcrumb Start      	=-->
        <!--=====================================-->
        <div class="breadcrumb-wrap-style-2" data-bg-color="" style="background-color: #7f7f7f;">
            <div class="inner-banner-title">
                <h1 class="title">ISTHOG-GUINEE (INSTITUT DE STRATEGIE, DU TOURISME, DE L'HOTELLERIE ET DE GESTION)</h1>
            </div>
        </div>
        <!--=====================================-->
        <!--=     	 	Breadcrumb End     		=-->
        <!--=====================================-->
        <!--=====================================-->
        <!--=         Single Blog Start     	=-->
        <!--=====================================-->
        <section class="apropos-wrap">
            <div class="container">
                <div class="main-figure-box">
                    <img src="media/etablissement/Tourisme.jpg" alt="" />
                </div>
                <div class="blog-content-box">
                    <h2 class="main-title text-center">
                        DESCRIPTION & RENSEIGNEMENTS
                    </h2>
                    <div class="d-flex mb-4" style="justify-content: space-around;">
                        <a href="#" class="item-btn btn-fill mr-5" tabindex="-1" style="background-color: #32ac46;">Commencer votre inscription</a>
                        <a href="#" class="item-btn btn-fill" tabindex="-1">Télécharger fichier</a>
                    </div>
                    <p>
                        <span>
                            ISTHOG a vu le jour il y’a 18 ans et un institut spécialisé dans la formation hôtelière et touristique. A ce jour il a formé 1150 étudiants de toute nationalité confondue dans les options suivantes : <br>
                            <h4 class="inner-title" style="color: #32ac46;"> BEP : </h4>
                            <h4 class="inner-title"> Cuisine Pâtisserie </h4>
                            <h4 class="inner-title"> Restaurant Bar </h4>
                            <h4 class="inner-title"> Hébergement </h4>
                            <h4 class="inner-title"> Tourisme </h4>
                            <h4 class="inner-title" style="color: #32ac46;"> BTS : </h4>
                            <h4 class="inner-title"> Gestion Hôtellerie </h4>
                            <h4 class="inner-title"> Tourisme </h4>
                            <h4 class="inner-title"> Gestion Entreprise de Tourisme </h4>
                            <h4 class="inner-title"> Hôtesse de l’air </h4> <br>
                            <p> La durée de la formation est de 03 ans. </p>
                        </span>
                    </p>
                    <p>
                        <span>
                            <h5 style="margin-top: 20px;margin-bottom: 0;"> Nos horaires d'ouverture </h5> 
                            <span style="font-weight: 500; color: black;">LUNDI :</span> <strong> 08H00 – 18H00 </strong> <br>
                            <span style="font-weight: 500; color: black;">MARDI :</span> <strong> 08H00 – 18H00 </strong> <br>
                            <span style="font-weight: 500; color: black;">MERCREDI :</span> <strong> 08H00 – 18H00 </strong> <br>
                            <span style="font-weight: 500; color: black;">JEUDI :</span> <strong> 08H00 – 18H00 </strong> <br>
                            <span style="font-weight: 500; color: black;">VENDREDI :</span> <strong> 08H00 – 18H00 </strong> <br>
                        </span>
                    </p>

                    <h3 class="inner-title">
                        LES INSCRIPTIONS ET REINSCRIPTIONS ONT DEBUTE DES LE 1ER SEPTEMBRE 
                    </h3>
                    <p>
                        <span>
                            <h4> BP: 2676 - Conakry</h4> 
                            <h4> Contacts : 623 031 273 / 622 253 400 / 621 054 103 / 664 568 701</h4>
                            <h4> isthog.guinee@yahoo.fr</h4> <br>
                            <strong style="color:  #32ac46;;"> NB : TOUT PAIEMENT SE FAIT SUR LA PLATEFORME SCEM </strong>
                        </span>
                    </p>
                </div>
                
            </div>
        </section>
        <!--=====================================-->
        <!--=      	    Single Blog End      	=-->
        <!--=====================================-->
    </div>
</template>